<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";

export default {
  name: 'App',

  data: () => ({
    //GET_USER
  }),
  created() {
    

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('GET_USER', user)
      } 
    });

  },
 
}
</script>

<style lang="scss">

/* Fonts */ 
@font-face {
    font-family: 'Icon';
    src:url('/assets/fonts/icon.?c9l32h');
    src:url('/assets/fonts/icon.eot?c9l32h#iefix') format('embedded-opentype'),
        url('/assets/fonts/icon.ttf?c9l32h') format('truetype'),
        url('/assets/fonts/icon.woff?c9l32h') format('woff'),
        url('/assets/fonts/icon.svg?c9l32h#icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: Monaco;
  font-style: normal;
  src: url("/assets/fonts/monaco.ttf");
}

@font-face {
  font-family: SFUIText;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/SF-UI-Text-Light.otf");
}

@font-face {
  font-family: SFUIText;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/SF-UI-Text-Regular.otf");
}

@font-face {
  font-family: SFUIText;
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/SF-UI-Text-Medium.otf");
}

@font-face {
  font-family: SFUIText;
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/SF-UI-Text-Bold.otf");
}

@font-face {
    font-family: 'PT Serif';
    src: url('/assets/fonts/PTF55F_W.eot');
    src:
         url('/assets/fonts/PTF55F_W.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/PTF55F_W.woff') format('woff'),
         url('/assets/fonts/PTF55F_W.ttf') format('truetype'),
         url('/assets/fonts/PTF55F_W.svg#PTSerif-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

body *{
 font-family: 'Encode Sans', sans-serif !important;
}
</style>
