
import * as firebase from "firebase";


  var config = {
    apiKey: "AIzaSyB4d6Q_zS0_WQ9RFe40x3mhRw_ZaQkKr8k",
    authDomain: "ordco-2df86.firebaseapp.com",
    databaseURL: "https://ordco-2df86-default-rtdb.firebaseio.com",
    projectId: "ordco-2df86",
    storageBucket: "ordco-2df86.appspot.com",
    messagingSenderId: "189883467977",
    appId: "1:189883467977:web:9756cad893c9c873a4a518",
    measurementId: "G-S31D016CKB"
  }; // 4. Get Firebase Configuration
  
  firebase.default.initializeApp(config);
  
  if (firebase.default.messaging.isSupported()){
    const messaging = firebase.default.messaging();
    
    messaging.usePublicVapidKey("BJdkZ-C8eL9bdPgkd10w3tqDboHkfF3MFG_uP-Juj4DZ8OElEZvyFzib1K1pY45RhUoD__M4RxEpPyy2d6aRkwQ"); // 1. Generate a new key pair
    
    // Request Permission of Notifications
    messaging.requestPermission().then(() => {
      console.log('Notification permission granted.');
    
      // Get Token
      messaging.getToken().then((token) => {
        console.log(token)
      })
    
  }).catch((err) => {
    console.log('Unable to get permission to notify.', err);
  });
}
