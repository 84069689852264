import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import axios from 'axios'

const state = () => ({
  isAuthReady: false,
  user: {},
  courts: null,
  dispute: null,
  settings: {
    maintenance: false
  }
})

export default createStore({
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
  }
})
