import axios from 'axios'

function get_url(slug) {
  var base_url = window.location.origin;
  if (base_url.includes('localhost'))  base_url = 'http://localhost:8000'
  else  base_url = 'https://wordapi.kazna.tech'
  var urls = {
    'court_list': '/api/court-list',
    'categories_list': '/api/categories-list',
    'types_list': '/api/types-list',
    'user_info':'/api/user-info',
    'add_sub':'/api/add-sub',
    'delete_sub':'/api/delete-sub'
  }
  return base_url+urls[slug]
}


const GET_TYPES = async (ctx) => {

  try {
    var url = get_url('types_list')
    const res = await axios.get(url);
    return res.data

  } catch (e) {

    console.log(e)
    throw e

  }
}


const GET_COURT = async (ctx) => {

  try {
    var url = get_url('court_list')
    const res = await axios.get(url);
    return res.data

  } catch (e) {

    console.log(e)
    throw e

  }
}
const GET_CATEGORIES = async (ctx) => {

  try {
    var url = get_url('categories_list')
    const res = await axios.get(url);
    return res.data

  } catch (e) {

    console.log(e)
    throw e

  }


}


const DELETE_SUB = async (ctx,data) => {
  try {
    var url = get_url('delete_sub')
    const res = await axios.post(url, data);
    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}


const ADD_SUB = async (ctx,data) => {
  try {
    var url = get_url('add_sub')
    const res = await axios.post(url, data);
    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}


const GET_USER = async (ctx,data) => {
  try {
    var url = get_url('user_info')
    url = url + '?uid='+ data.uid+'&email='+data.email 
    const res = await axios.get(url);
    await ctx.commit('SET_USER', res.data.data)

  } catch (e) {
    console.log(e)
    throw e
  }
}


export default {
  GET_COURT,
  GET_CATEGORIES,
  GET_TYPES,
  GET_USER,
  ADD_SUB,
  DELETE_SUB
}
